






















































































































































import useAbstract from "@/use/abstract";
import { defineComponent, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    MAbstractsOptions: () =>
      import("@/components/molecules/abstracts/m-abstracts-options.vue"),
    MSingleReviewersAssign: () =>
      import("@/components/molecules/abstracts/m-single-reviewers-assign.vue"),
    MAbstractDetails: () =>
      import("@/components/molecules/abstracts/m-abstract-details.vue"),
    MAbstractEdit: () =>
      import("@/components/molecules/abstracts/m-abstract-edit.vue"),
    MAbstractAccept: () =>
      import("@/components/molecules/abstracts/m-abstract-accept.vue"),
    MAbstractHistory: () =>
      import("@/components/molecules/abstracts/m-abstract-history.vue"),
  },
  props: {
    results: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, { root }) {
    const { getTypeName, getStatusName } = useAbstract({ root });

    const state = reactive({
      singleSelect: false,
      expanded: [],
      selected: [],
      headers: [
        {
          value: "index",
          text: root.$tc("layout.misc.orderNumber"),
          width: 1,
          sortable: false,
        },
        {
          value: "title",
          text: root.$tc("panel.event.abstract.abstractList.list.title"),
        },
        {
          value: "status",
          text: root.$tc("panel.event.abstract.abstractList.list.status"),
          sortable: false,
          width: 1,
          align: "right",
        },
        {
          value: "sessionTopic",
          text: root.$tc("panel.event.abstract.abstractList.list.sessionTopic"),
          align: "right",
          width: "100px",
        },
        {
          value: "participant",
          text: root.$tc("panel.event.abstract.abstractList.list.sender"),
          align: "right",
          width: "100px",
        },
        {
          value: "authors",
          text: root.$tc("panel.event.abstract.abstractList.list.authors"),
          align: "right",
          width: 1,
        },
        {
          value: "type",
          text: root.$tc("panel.event.abstract.abstractList.list.type"),
          align: "right",
          width: "150px",
        },
        {
          value: "createdAt",
          text: root.$tc("panel.event.abstract.abstractList.list.addDate"),
          align: "center",
          width: "120px",
        },
        {
          value: "average",
          text: root.$tc("panel.event.abstract.abstractList.list.average"),
          align: "right",
          width: 1,
          sortable: false,
        },
        { value: "languageVersion", text: "", align: "right", width: 1 },
        {
          value: "actions",
          text: "",
          sortable: false,
          align: "right",
          width: 1,
        },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      success: false,
      error: false,
      total: props.results.length,
      options: {
        page: 1,
        itemsPerPage: props.results.length,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      title: "",
      titleTimeout: undefined as any,
      author: "",
      authorTimeout: undefined as any,
      reviewedDialog: {},
      editDialog: {},
      acceptDialog: false,
      acceptItem: {},
      acceptIndex: -1,
      historyDialog: false,
      historyItem: {},
      showDeleted: false,
      showUnassigned: false,
      showEdited: false,
    });

    const acceptDialog = (item: any) => {
      state.acceptItem = Object.assign({}, item);
      state.acceptDialog = true;
    };

    const historyDialog = (item: any) => {
      state.historyItem = Object.assign({}, item);
      state.historyDialog = true;
    };

    const changeStatusOrType = (
      status: string,
      id: string,
      type: string,
      isActive: boolean
    ) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        abstracts: [id],
        type: type,
        status: status,
        isActive,
      };

      state.loading = true;

      axiosInstance
        .put(`event/${root.$route.params.id}/abstract/bulk`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    return {
      state,
      acceptDialog,
      historyDialog,
      changeStatusOrType,
      getTypeName,
      getStatusName,
    };
  },
});
